import { LineItem } from "ui/src/types";
import * as styles from "../Item.module.scss";
import cn from "classnames";
import { ButtonResult, ButtonType, PopupSize, QuantitySelector, popup } from "ui";
import { Inventories } from "ui/src/components/Inventories/Inventories";

type RemanufacturedItemProps = {
    reman: LineItem;
    deposit: LineItem;
    sparePartUrl: string;
    onChange: (remanCode: string, depositCode: string, quantity: number) => void;
    onRemove: (remanCode: string, depositCode: string, callback: () => void) => void;
}
const translations = window.app.preloadState.translation;
const itemsWithoutPriceAllowedInCart = (window as any).app.preloadState.itemsWithoutPriceAllowedInCart;

export const RemanufacturedItem = ({ reman, deposit, sparePartUrl, onChange, onRemove }: RemanufacturedItemProps) => {

    return (
        <div className={styles.remanAndDepositContainer}>
            <div>
                <RemanItem
                    item={reman}
                    sparePartUrl={sparePartUrl}
                    onChange={onChange.bind(null, reman.code, deposit.code)}
                    onRemove={onRemove.bind(null, reman.code, deposit.code)}
                    />
            </div>
            <div>
                <DepositItem
                    item={deposit}
                    sparePartUrl={sparePartUrl}
                    onChange={onChange.bind(null, reman.code, deposit.code)}
                    onRemove={onRemove.bind(null, reman.code, deposit.code)}
                    />
            </div>
        </div>
    );
}

type ItemProps = {
    item: LineItem;
    sparePartUrl: string;
    onChange: (quantity: number) => void;
    onRemove: (callback: () => void) => void;
};

const RemanItem = ({ item, sparePartUrl, onChange, onRemove }: ItemProps) => {

    const minimumQuantity = item.inventory?.minimumQuantity ?? 0;
    const maximumQuantity = item.inventory?.maximumQuantity ?? 0;
    const quantityStep = item.inventory?.quantityStep ?? 0;
    const isAvailableInWarehouse = item.inventory?.warehouse?.isAvailable ?? false;
    const isAvailableInBranch = item.inventory?.branch?.isAvailable ?? false;

    const showInventoryPopup = (ev: React.MouseEvent) => {
        ev.preventDefault();
        popup(
            translations["inventoryPopup.stockStatusAtFacility"],
            <Inventories itemCode={item.code} itemDisplayName={item.displayName} />,
            [
                { label: translations["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    const onRemoveClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onRemove(() => { });
    };

    const onRemoveHandler = (callback: () => void) => {
        onRemove(callback);
    }

    const onChangeHandler = (quantity: number) => {
        onChange(quantity);
    }

    return <>
        <div className={styles.group}>
            <a className={styles.group} href={sparePartUrl}>
                <img className="objectFitContain" src={(item.imageUrl ?? "") + "?width=80"} alt={item.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{item.displayName}</p>
                    <p className={cn(styles.articleNumber, "firstLetterCapital")}>
                        {item.code && `${translations["checkout.articleNo"]}: ${item.code}`}{" "}
                    </p>
                    {item.labels?.length > 0 &&
                        <p className={cn(styles.labels)}>
                            {item.labels.map(label =>
                                <span key={label.text} className={styles.label}>{label.text}</span>
                            )}
                        </p>
                    }
                    <p className={styles.additionalInfo}>
                        <span className={"firstLetterCapital"}>{translations["checkout.quantityFrp"]}: {item.inventory?.packageQuantity ?? 0}</span>
                        {item.unitId && <span className={"firstLetterCapital"}>{translations["checkout.unit"]}: {item.unitId}</span>}
                        <span className="d-contents d-lg-none">
                            {item.unitPriceAmount > 0 || !itemsWithoutPriceAllowedInCart ? <>
                            <span
                                className={cn("truckIcon", {
                                    [styles.redTruck]: !isAvailableInWarehouse,
                                    [styles.truck]: isAvailableInWarehouse,
                                })}
                            >
                                {isAvailableInWarehouse ? <span dangerouslySetInnerHTML={{ __html: item.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                            </span>
                            {item.inventory?.branch?.branchName ?
                                <span
                                    onClick={showInventoryPopup}
                                    className={cn({
                                        [styles.check]: isAvailableInBranch,
                                        [styles.close]: !isAvailableInBranch,
                                    })}
                                >
                                    {item.inventory?.branch?.branchName}
                                </span>
                                :
                                <span
                                    onClick={showInventoryPopup}
                                >
                                    {translations["inventories.selectBranch"]}
                                </span>
                                }
                            </> : <span className={cn("firstLetterCapital truckIcon", styles.truck)}>
                                {translations["common.deliveryTimeToBeChecked"]}
                            </span>}
                        </span>
                    </p>
                </div>
            </a>
            <div className={styles.md}>
                <a href="#" onClick={onRemoveClickHandler} className={styles.remove}></a>
            </div>
        </div>
        <div className={styles.inventories}>
            <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.stockStatus"]}:</div>
            <div className={cn(styles.md, styles.inventoriesLabels)}>
                {item.unitPriceAmount > 0 || !itemsWithoutPriceAllowedInCart ? <>
                <span
                    className={cn("firstLetterCapital truckIcon", {
                        [styles.redTruck]: !isAvailableInWarehouse,
                        [styles.truck]: isAvailableInWarehouse
                    })}
                >
                    {isAvailableInWarehouse ? <span dangerouslySetInnerHTML={{ __html: item.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : translations["inventoryPopup.notInStock"]}
                </span>
                <span
                    onClick={showInventoryPopup}
                    className={cn({
                        [styles.check]: isAvailableInBranch,
                        [styles.close]: !isAvailableInBranch,
                    })}
                >
                    {item.inventory?.branch?.branchName}
                    </span>
                </> : <span className={cn("firstLetterCapital truckIcon", styles.truck)}>
                    {translations["common.deliveryTimeToBeChecked"]}
                </span>}
            </div>
        </div>
        <div className={styles.prices}>
            {item.unitPriceAmount > 0 && <>
                {item.hasDiscount && (
                    <div className="d-md-none">
                        <p className={styles.discountedPrice}>{item.unitDiscountedPrice}</p>
                    </div>
                )}
                <div>
                    <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.price"]}:</div>
                    <p
                        className={cn(styles.originalPrice, {
                            [styles.strike]: item.hasDiscount,
                        }, "d-md-none")}
                    >
                        {item.unitBasePrice}
                    </p>
                    <p
                        className={cn(styles.sum, "d-none d-md-block")}
                    >
                        {item.unitDiscountedPrice ?? item.unitBasePrice}
                    </p>
                </div>
                {item.hasDiscount && (
                    <div>
                        <div className="firstLetterCapital d-inline-block">{translations["checkout.discount"]}:</div>
                        <span>
                            <span
                                className={cn(styles.strike, styles.originalPrice, "m-right--x3 d-none d-md-inline")}
                            >
                                {item.unitBasePrice}
                            </span>
                            <span className={cn(styles.discount, "d-inline-block")}>{item.discountPercentageDisplay}</span>
                        </span>
                    </div>
                )}
            </>}
        </div>
        <div className={styles.quantity}>
            <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.quantity"]}:</div>
            <QuantitySelector
                buttonLabel={translations["checkout.buy"]}
                buttonLabelAdded={translations["sparePartItem.added"]}
                forceMinValue={true}
                showLogin={false}
                showNotAuthorized={false}
                showRequestQuotationByEmail={false}
                showContactSupport={false}
                showBuyButton={true}
                requestQuotationByEmail=""
                contactSupportLink=""
                step={quantityStep}
                min={minimumQuantity}
                max={maximumQuantity}
                initialValue={item.quantity}
                onRemove={onRemoveHandler}
                onChange={onChangeHandler}
                newDesign={false}/>
        </div>
        <div className={styles.sumWrapper}>
            {item.unitPriceAmount > 0 && <>
                <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.total"]}:</div>
                <p className={styles.sum}>{item.totalPrice}</p>
            </>}
            <a href="#" onClick={onRemoveClickHandler} className={cn(styles.nmd, styles.remove)}>
                {translations["checkout.removeItem"]}
            </a>
        </div>
    </>;
}

const DepositItem = ({ item, sparePartUrl, onChange, onRemove }: ItemProps) => {

    const minimumQuantity = 1;
    const maximumQuantity = 999999;
    const quantityStep = 1;

    const onRemoveHandler = (callback: () => void) => {
        onRemove(callback);
    }

    const onChangeHandler = (quantity: number) => {
        onChange(quantity);
    }

    return <>
        <div className={styles.group}>
            <a className={cn(styles.group, styles.depositAnchor)} href={sparePartUrl}>
                <img className="objectFitContain" src={(item.imageUrl ?? "") + "?width=80"} alt={item.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{item.displayName}</p>
                    <p className={cn(styles.articleNumber, "firstLetterCapital")}>
                        {item.code && `${translations["checkout.articleNo"]}: ${item.code}`}{" "}
                    </p>
                    {item.labels?.length > 0 &&
                        <p className={cn(styles.labels)}>
                            {item.labels.map(label =>
                                <span key={label.text} className={styles.label}>{label.text}</span>
                            )}
                        </p>
                    }
                </div>
            </a>
        </div>
        <div className={styles.prices}>
            {item.unitPriceAmount > 0 && <div>
                <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.price"]}:</div>
                <p className={cn(styles.originalPrice, "d-md-none")}>
                    {item.unitBasePrice}
                </p>
                <p className={cn(styles.sum, "d-none d-md-block")}>
                    {item.unitDiscountedPrice ?? item.unitBasePrice}
                </p>
            </div>}
        </div>
        <div className={styles.quantity}>
            <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.quantity"]}:</div>
            <QuantitySelector
                key={item.code + item.quantity}
                buttonLabel={translations["checkout.buy"]}
                buttonLabelAdded={translations["sparePartItem.added"]}
                forceMinValue={true}
                showLogin={false}
                showNotAuthorized={false}
                showRequestQuotationByEmail={false}
                showContactSupport={false}
                showBuyButton={true}
                requestQuotationByEmail=""
                contactSupportLink=""
                step={quantityStep}
                min={minimumQuantity}
                max={maximumQuantity}
                initialValue={item.quantity}
                onRemove={onRemoveHandler}
                onChange={onChangeHandler}
                disabled={true}
                newDesign={false}/>
        </div>
        <div className={styles.sumWrapper}>
            {item.unitPriceAmount > 0 && <>
                <div className={cn(styles.md, "firstLetterCapital")}>{translations["checkout.total"]}:</div>
                <p className={styles.sum}>{item.totalPrice}</p>
            </>}
        </div>
    </>;
}